import React from 'react';
import { H4, Paragraph } from '@thisisdevelopment/akzonobel-core';
import { LinkCardStyled, StyledPropTypes } from './styled';
import CmsImage from 'components/CmsImage';
import { isExternalLink } from 'util/path';

type PropTypes = {
  title: string;
  text?: string;
  to: string;
  image: number;
};

function LinkCard({ title, text, to, image, color }: PropTypes & StyledPropTypes) {
  const children = (
    <>
      <CmsImage id={image} />
      <H4 mt="20px">{title}</H4>
      {text && <Paragraph>{text}</Paragraph>}
    </>
  );

  return to && isExternalLink(to) ? (
    <LinkCardStyled as="a" href={to} target="_blank" color={color}>
      {children}
    </LinkCardStyled>
  ) : (
    <LinkCardStyled color={color} to={to}>
      {children}
    </LinkCardStyled>
  );
}

export default LinkCard;

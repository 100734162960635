import React, { FC, useEffect, useState } from 'react';
import { useCmsFile, StyledWrapperPropType } from '@thisisdevelopment/akzonobel-core';
import CmsImage from 'components/CmsImage';
import { CmsVideoStyled, VideoEmbed } from './styled';

type PropType = {
  coverId: number;
  videoId: number;
};

const CmsVideo: FC<PropType & StyledWrapperPropType> = ({
  coverId,
  videoId,
  ...rest
}: PropType & StyledWrapperPropType) => {
  const { file, fetchFile } = useCmsFile();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  useEffect(() => {
    fetchFile(videoId);
  }, [videoId, fetchFile]);

  return (
    <CmsVideoStyled {...rest} onClick={() => setIsPlaying(true)}>
      {!isPlaying && <CmsImage id={coverId} />}
      {isPlaying && (
        <VideoEmbed
          id="ytplayer"
          src={`https://www.youtube.com/embed/${file.embed}?autoplay=1&rel=0`}
          allow="autoplay"
        />
      )}
    </CmsVideoStyled>
  );
};

export default CmsVideo;

import React, { FC } from 'react';
import CmsImage from 'components/CmsImage';
import { H3 } from '@thisisdevelopment/akzonobel-core';
import { Link } from 'react-router-dom';
import { CmsRelatedArticleStyled } from './styled';

export type CmsRelatedArticleType = {
  id: number;
  title: string;
  headline: string;
  summary: string;
  body: string;
  image: number;
  thumbnail: number;
  category: string[];
  sub_category: string;
  highlight: boolean;
  friendly_url_title: string;
};

type PropType = {
  relatedArticle: CmsRelatedArticleType;
};

const CmsRelatedArticle: FC<PropType> = ({ relatedArticle }: PropType) => {
  return (
    <CmsRelatedArticleStyled>
      <CmsImage id={relatedArticle.image} />
      <H3>{relatedArticle.title}</H3>
      <Link
        to={`/en/inspiration/article/${relatedArticle.id}/${relatedArticle.friendly_url_title}`}
      >
        Get inspired &gt;
      </Link>
    </CmsRelatedArticleStyled>
  );
};

export default CmsRelatedArticle;

import React from 'react';
import CmsSection, { CmsSectionType } from 'components/CmsSection';
import { FlexCol } from '@thisisdevelopment/akzonobel-core';

type PropTypes = {
  sections: CmsSectionType[];
};

function CmsSections({ sections }: PropTypes) {
  return (
    <FlexCol>
      {sections?.map((section, index: number) => (
        <CmsSection key={`Section_${index}`} sectionKey={`Section_${index}`} section={section} />
      ))}
    </FlexCol>
  );
}

export default CmsSections;

import React from 'react';
import CmsImage from 'components/CmsImage';
import { ContainerInner } from 'elements';
import { PropTypes, CmsBannerStyled, BannerTextLinkStyled, Caption } from './styled';

function CmsBanner({ imageId, title, subtitle, headline, buttonLink, buttonLabel }: PropTypes) {
  return (
    <CmsBannerStyled>
      <CmsImage id={imageId} />
      {(title || subtitle || headline) && (
        <Caption>
          <BannerTextLinkStyled to={buttonLink ? buttonLink : '/en/'}>
            <ContainerInner>
              {title && <h1>{title}</h1>}
              {subtitle && <h2>{subtitle}</h2>}
              {headline && <p>{headline}</p>}
              {buttonLink && buttonLabel && (
                <p className="story-fake-link">{buttonLabel}&nbsp;&gt;</p>
              )}
            </ContainerInner>
          </BannerTextLinkStyled>
        </Caption>
      )}
    </CmsBannerStyled>
  );
}

export default CmsBanner;

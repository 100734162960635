import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Color,
  InputSelect,
  useEcom,
  useArticle,
  Product,
  Image,
  H3,
  ButtonElement,
  getColorNameByBgColorHex,
  H4,
  Button,
  LinkButton,
  config,
} from '@thisisdevelopment/akzonobel-core';
import { useTheme } from 'styled-components';
import {
  ProductPhoto,
  ColorTile,
  ArticleForm,
  ChooseColorButton,
  PriceAndQuantity,
  Actions,
  StockIndicator,
} from './styled';
import ProductInfo from 'components/ProductInfo';
import { hasColors, isAccessory, isColorCard, isColorCardByGlobalId } from 'util/product';
import InputNumber from 'components/InputNumber';
import TagManager from 'react-gtm-module';
import { getArgsProduct, getArgsCartAdd } from 'util/datalayer';

type PropTypes = {
  product?: Product;
  color?: Color;
  colorProducts?: Product[];
  mode: 'Product' | 'Color';
  onChangeColor: () => void;
  setOrderModalIsOpen?: Function;
};

function Article({
  product,
  color,
  mode,
  onChangeColor,
  setOrderModalIsOpen,
  colorProducts,
}: PropTypes) {
  const theme = useTheme();
  const history = useHistory();
  const { article, fetchArticle, setArticle } = useArticle();
  const { addToCart, checkStock, resetStockCheck, isLoading, hasStock, stockInfo } = useEcom();
  const [packSize, setPackSize] = useState<string | undefined>();
  const [quantity, setQuantity] = useState<number>(1);

  const isProductMode = mode === 'Product';
  const isColorMode = mode === 'Color';
  const productHasPackSizes = product?.packSizes && product.packSizes.length > 0;

  const textColor = useMemo(
    () => (isColorMode && color ? getColorNameByBgColorHex(color.rgb) : theme.colors.black),
    [color, isColorMode, theme.colors.black]
  );

  useEffect(() => {
    if (productHasPackSizes) {
      setPackSize(product.packSizes[0]);
    }
  }, [product, productHasPackSizes]);

  useEffect(() => {
    if (product && product.validEcomData && color && packSize && config.ecomEnabled) {
      fetchArticle(product.globalId, color.colorCollectionColorId, packSize);
    } else if (product && product.validEcomData && packSize && config.ecomEnabled) {
      fetchArticle(product.globalId, null, packSize);
    }
  }, [packSize, product, color, fetchArticle]);

  useEffect(() => {
    if (color && article) {
      checkStock(article.articleNumber, color.colorCollectionColorId);
    } else if (article) {
      checkStock(article.articleNumber, null);
    }
  }, [article, color, checkStock]);

  useEffect(() => {
    if (product) {
      TagManager.dataLayer(getArgsProduct({ product }));
    }
  }, [product]);

  const onChangeProduct = (value: string) => {
    setPackSize(undefined);
    setArticle(undefined);
    resetStockCheck();
    history.replace(`?globalId=${value}`);
  };

  const handleColorChange = () => {
    setArticle(undefined);
    onChangeColor();
  };

  return (
    <>
      {isProductMode && product && (
        <ProductPhoto bgColor={color ? `#${color.rgb}` : theme.colors.white}>
          <Image src={product.packshots?.[0].s} alt={product.name} />
        </ProductPhoto>
      )}
      {isColorMode && color && (
        <ColorTile style={{ backgroundColor: `#${color.rgb}`, color: textColor }}>
          <H3>{color.defaultColorName}</H3>
          <ButtonElement onClick={handleColorChange}>Change Colour</ButtonElement>
        </ColorTile>
      )}
      <ArticleForm>
        {isProductMode && product && hasColors(product) && (
          <ChooseColorButton onClick={handleColorChange}>
            {color ? `${color.defaultColorName}` : 'Choose a colour'}
          </ChooseColorButton>
        )}
        {isColorMode && colorProducts && (
          <InputSelect
            placeholder="Choose a product"
            options={colorProducts.map(({ name, globalId }) => ({ label: name, value: globalId }))}
            onChange={onChangeProduct}
            value={product?.globalId}
          ></InputSelect>
        )}
        {product && productHasPackSizes && (
          <InputSelect
            placeholder="Choose a size"
            options={product?.packSizes.map((value) => ({ label: value, value }))}
            onChange={setPackSize}
            value={packSize}
          ></InputSelect>
        )}
        {product && stockInfo && (
          <Button color="tertiary" size="small" disabled>
            <StockIndicator indicator={stockInfo.indicator}>
              <div />
              {stockInfo.shortMessage}
            </StockIndicator>
          </Button>
        )}
        {product && productHasPackSizes && !config.ecomEnabled && !isColorCardByGlobalId(product) && (
          <LinkButton color="secondary" mt="13px" to={`/en/stores`}>
            FIND A STORE
          </LinkButton>
        )}
        {product && productHasPackSizes && !config.ecomEnabled && isColorCardByGlobalId(product) && (
          <Button color="secondary" mt="13px" onClick={() => setOrderModalIsOpen(true)}>
            ORDER A COLOUR CARD
          </Button>
        )}
        {product && !productHasPackSizes && (
          <Button color="tertiary" size="small" disabled>
            CURRENTLY UNAVAILABLE ONLINE
          </Button>
        )}
        {article && (
          <PriceAndQuantity>
            <H4>
              Price: <strong>{article.price.friendlyPrice}</strong>
            </H4>
            <InputNumber value={quantity} onChange={setQuantity} label="Quantity" />
            {!hasStock && (
              <Button color="tertiary" size="small" disabled>
                CURRENTLY UNAVAILABLE ONLINE
              </Button>
            )}
          </PriceAndQuantity>
        )}
        <Actions>
          {article &&
            product &&
            (color || isAccessory(product) || isColorCard(product)) &&
            hasStock && (
              <Button
                color="secondary"
                onClick={() => {
                  addToCart(
                    article.articleNumber,
                    color ? color.colorCollectionColorId : null,
                    quantity
                  );
                  TagManager.dataLayer(getArgsCartAdd({ product, article, color, quantity }));
                }}
                disabled={isLoading}
              >
                {isLoading ? 'PROCESSING...' : 'ADD TO BASKET'}
              </Button>
            )}
          {article && (
            <LinkButton color="secondary" to={`/en/stores`}>
              FIND A STORE
            </LinkButton>
          )}
        </Actions>
      </ArticleForm>
      {product && !isColorCard(product) && !isAccessory(product) && (
        <ProductInfo product={product} />
      )}
    </>
  );
}

export default Article;

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from 'theme';

export type LinkCardColor = 'grey' | 'dark-grey';

export type StyledPropTypes = {
  color: LinkCardColor;
};

const colorStyles = {
  grey: { bgColor: theme.colors.brandGray100, color: theme.colors.black },
  'dark-grey': { bgColor: theme.colors.brandGray450, color: theme.colors.white },
};

export const LinkCardStyled = styled(Link)<StyledPropTypes>`
  background-color: ${({ color }) => colorStyles[color as LinkCardColor]?.bgColor};
  color: ${({ color }) => colorStyles[color as LinkCardColor]?.color};
  padding: 25px;
  text-decoration: none;
  text-align: center;

  h4 {
    color: ${({ theme }) => theme.colors.brandGold};
    font-weight: 600;
    font-size: 1.2rem;
  }

  p {
    font-size: 1rem;
  }

  img {
    object-fit: cover;
    width: 100%;
  }

  @media (min-width: 769px) {
    padding: 50px;

    h4 {
      font-size: 1.4rem;
    }

    p {
      font-size: 1.2rem;
    }

    img {
      height: 350px;
    }
  }
`;

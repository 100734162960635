export type Meta = {
  title: string;
  description: string;
  keywords: string;
};

function replaceMetaValues(meta: Meta, values: any) {
  return Object.keys(values).reduce(
    (res, key) => ({
      ...res,
      title: res.title.replace(new RegExp(`\\$${key}`, 'g'), values[key]),
      description: res.description.replace(new RegExp(`\\$${key}`, 'g'), values[key]),
    }),
    meta
  );
}

export function updateMeta(meta: Meta, values?: any) {
  const { title, description, keywords } = values ? replaceMetaValues(meta, values) : meta;
  document.title = title;
  document.querySelector('meta[name=description]')?.setAttribute('content', description);
  document.querySelector('meta[name=keywords]')?.setAttribute('content', keywords);
}

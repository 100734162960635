import styled, { keyframes } from 'styled-components';
import theme from 'theme';
import { Link } from 'react-router-dom';

export type PropTypes = {
  imageId: number;
  title?: string;
  subtitle?: string;
  headline?: string;
  buttonLink?: string;
  buttonLabel?: string;
};

export const fadeInFocus = keyframes`
  from {
    opacity: 0;
    filter: blur(5px);
  }
  to {
    opacity: 1;
    filter: unset;
  }
`;

export const CmsBannerStyled = styled.figure`
  position: relative;
  padding: 0;
  margin: 0;

  animation: ${fadeInFocus} 0.8s;

  img {
    display: block;
    object-fit: cover;
    width: 100%;
    min-height: 700px;
  }

  a {
    font-family: 'Source Sans Pro', sans-serif;
    text-underline-position: under;
    font-weight: 600;
  }

  @media ${theme.mediaQuery.tablet} {
    img {
      min-height: 400px;
    }

    a {
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.brandGold};
    }
  }

  @media ${theme.mediaQuery.mobile} {
    img {
      min-height: 250px;
    }
  }

  @media ${theme.mediaQuery.desktop} {
    a {
      font-size: 1.1rem;
      color: ${theme.colors.brandBlueDarker};
    }
  }
`;

export const BannerTextLinkStyled = styled(Link)`
  text-decoration: none;
`;

export const Caption = styled.figcaption`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;

  h1 {
    font-family: 'Source Serif Pro', serif;
    color: ${({ theme }) => theme.colors.brandGold};
    font-style: italic;
    font-weight: 400;
    font-size: 2.4rem;
    margin: 0;
    width: 40%;
  }

  h2 {
    color: ${({ theme }) => theme.colors.brandBlue};
    font-weight: 600;
    font-size: 2.4rem;
    margin: 0;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 40%;

    &:after {
      content: '';
      width: 100px;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.brandBlue};
    }
  }

  p {
    color: ${({ theme }) => theme.colors.white};
    margin: 25px 0;
    width: 40%;
    font-size: 1.3rem;
  }

  .story-fake-link {
    font-size: 1.1rem;
    color: ${({ theme }) => theme.colors.brandBlueDarker};
    text-decoration: underline;
  }

  @media ${theme.mediaQuery.tablet} {
    position: static;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 20px 0 40px 0;

    h1,
    h2 {
      font-family: 'Source Sans Pro', sans-serif;
      color: ${({ theme }) => theme.colors.brandGold};
      font-style: normal;
      font-weight: 600;
      font-size: 1.2rem;
      display: inline;
      text-transform: uppercase;
      margin-right: 5px;

      &:after {
        display: none;
      }
    }

    p {
      color: ${({ theme }) => theme.colors.black};
      margin: 25px 0;
      width: unset;
      font-size: 1rem;
    }
  }
`;

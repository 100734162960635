import React, { useEffect, useState } from 'react';
import {
  useEcom,
  Spacer,
  Paragraph,
  config,
  ExternalLinkButton,
  useProducts,
} from '@thisisdevelopment/akzonobel-core';
import { CartStyled, Badge, Items, Item, Footer, EmptyCart } from './styled';
import IconButton from 'components/IconButton';
import Modal from 'components/Modal';
import Cookies from 'universal-cookie';

function Cart() {
  const { cart, updateCart } = useEcom();
  const [changing, setChanging]: [boolean, Function] = useState(true);
  const [isOpen, setIsOpen]: [boolean, Function] = useState(false);
  const { products, fetchProducts } = useProducts();
  const cookies = new Cookies();

  useEffect(() => {
    if (cookies.get('orderNumber') && cookies.get('orderToken') && cookies.get('hasOrder')) {
      updateCart();
    }
    fetchProducts();
  }, [updateCart, fetchProducts]);

  useEffect(() => {
    if (cart && cart.item_count > 0) {
      setChanging(true);
      setTimeout(() => setChanging(false), 500);
    }
  }, [cart, setChanging]);

  const hasItems = cart && cart?.item_count > 0;

  const modalProps =
    cart && hasItems
      ? { title: 'Items in your basket:', subTitle: cart.item_count.toString() }
      : { title: 'Items in your basket' };

  const getProductNameById = (productId: string) =>
    products?.find(({ globalId }) => globalId === productId)?.name;

  return (
    <CartStyled>
      <IconButton icon="ShoppingBag" onClick={() => setIsOpen(true)} />
      {cart && cart.item_count > 0 && <Badge changing={changing}>{cart?.item_count}</Badge>}
      {isOpen && (
        <Modal {...modalProps} onClose={() => setIsOpen(false)} width="450px">
          {cart && hasItems ? (
            <>
              <Items>
                {cart.lineItems.map((item) => (
                  <Item key={`CartItem_${item.id}`}>
                    <Spacer width="70px" style={{ backgroundColor: `#${item.rgb}` }} />
                    <Spacer>
                      <Paragraph>
                        <strong>Colour:</strong> {item.color}
                      </Paragraph>
                      <Paragraph>
                        <strong>Finish:</strong> {getProductNameById(item.productId)}
                      </Paragraph>
                      <Paragraph>
                        <strong>Size:</strong> {item.size}
                      </Paragraph>
                      <Paragraph>
                        <strong>Quantity:</strong> {item.quantity}
                      </Paragraph>
                    </Spacer>
                  </Item>
                ))}
              </Items>
              <Footer>
                <ExternalLinkButton color="secondary" href={config.cartUrl} width="200px">
                  GO TO CHECKOUT
                </ExternalLinkButton>
              </Footer>
            </>
          ) : (
            <EmptyCart>Your shopping basket is empty</EmptyCart>
          )}
        </Modal>
      )}
    </CartStyled>
  );
}

export default Cart;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import IconButton from 'components/IconButton';
import {
  useDebounce,
  useSearch,
  Wrapper,
  Text,
  Image,
  useOnKeyDown,
  FlexRow,
} from '@thisisdevelopment/akzonobel-core';
import {
  SearchBarStyled,
  SearchField,
  SearchResults,
  SearchResultTitle,
  SearchResultList,
  SearchResultListItem,
  // ShowAllResults,
  Searching,
} from './styled';

type PropType = {
  onClose: () => void;
};

function SearchBar({ onClose }: PropType) {
  const theme = useTheme();
  const [searchText, setSearchText] = useState<string>('');
  const query = useDebounce(searchText, 400);
  const {
    results: colorResults,
    hasResults: hasColorResults,
    isLoading: isLoadingColor,
    fetchResults: fetchColorResults,
  } = useSearch();
  const {
    results: productResults,
    hasResults: hasProductResults,
    isLoading: isLoadingProduct,
    fetchResults: fetchProductResults,
  } = useSearch();

  useEffect(() => {
    fetchColorResults({ type: 'colors', query });
    fetchProductResults({ type: 'products', query });
  }, [query, fetchColorResults, fetchProductResults]);

  useOnKeyDown(['Escape'], () => {
    onClose();
  });

  return (
    <SearchBarStyled>
      <FlexRow position="relative" alignItems="center" margin="20px 20px 0 20px">
        <IconButton
          icon="Search"
          width="24px"
          height="24px"
          fill={theme.colors.brandGold}
          hooverFill={theme.colors.brandGray200}
          onClick={() => null}
          mr="15px"
        />

        <SearchField
          type="text"
          placeholder="Search for"
          autoFocus={true}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        ></SearchField>

        <IconButton
          icon="Close"
          width="20px"
          height="20px"
          fill={theme.colors.brandGold}
          hooverFill={theme.colors.brandGray200}
          onClick={onClose}
        />

        {query && (
          <SearchResults>
            {!isLoadingColor && hasColorResults && (
              <>
                <SearchResultTitle>Colours</SearchResultTitle>
                <SearchResultList>
                  {colorResults.map(({ id, display, description, snippet }) => (
                    <SearchResultListItem key={`ColorResult_${id}`}>
                      <Link to={`/en/colours/${id}/${snippet}`} onClick={() => onClose()}>
                        <Wrapper
                          bgColor={`#${display}`}
                          width="25px"
                          height="25px"
                          mr="10px"
                        ></Wrapper>
                        <Text>{description}</Text>
                      </Link>
                    </SearchResultListItem>
                  ))}
                </SearchResultList>
              </>
            )}
            {!isLoadingProduct && hasProductResults && (
              <>
                <SearchResultTitle>Products</SearchResultTitle>
                <SearchResultList>
                  {productResults.map(({ id, display, description, snippet }) => (
                    <SearchResultListItem key={`ProductResult_${id}`}>
                      <Link to={`/en/products/details/${snippet}`} onClick={() => onClose()}>
                        <Image
                          src={display}
                          objectFit="contain"
                          width="25px"
                          height="25px"
                          mr="10px"
                        />
                        <Text>{description}</Text>
                      </Link>
                    </SearchResultListItem>
                  ))}
                </SearchResultList>
              </>
            )}
            {/* {!isLoadingColor && !isLoadingProduct && (hasColorResults || hasProductResults) && (
              <ShowAllResults to={`/search?query=${query}`} onClick={() => onClose()}>
                Show all results
              </ShowAllResults>
            )} */}
            {(isLoadingColor || isLoadingProduct) && <Searching>Searching...</Searching>}
            {!isLoadingColor && !isLoadingProduct && !hasColorResults && !hasProductResults && (
              <Searching>No results found</Searching>
            )}
          </SearchResults>
        )}
      </FlexRow>
    </SearchBarStyled>
  );
}

export default SearchBar;

import styled from 'styled-components';
import { Wrapper, StyledWrapperPropType } from '../../elements';

export type StyledOwnPropTypes = {
  colorName?: string;
  spacing?: string;
};

export type StyledPropTypes = StyledOwnPropTypes & StyledWrapperPropType;

export const ColorTileStyled = styled(Wrapper)<StyledPropTypes>`
  position: relative;
`;

export const ColorName = styled.span<StyledOwnPropTypes>`
  ${(props) => props.theme.mixins.underlineHover(props.colorName)};

  font-family: ColourSansRegular;
  font-size: 1.5rem;
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    fill: ${(props) => props.colorName};
    margin-left: 10px;
  }
`;

export const Overlay = styled.div`
  position: relative;
  background-color: transparent;
  border-top-right-radius: inherit;
  border-bottom-left-radius: inherit;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:hover {
    cursor: pointer;
  }

  &:hover ${ColorName} {
    ${(props) => props.theme.mixins.underlineHovered};
  }
`;

export const ChangeColor = styled.span<StyledOwnPropTypes>`
  ${(props) => props.theme.mixins.underlineHover(props.colorName)};

  position: absolute;
  bottom: ${({ spacing }) => spacing};
  right: ${({ spacing }) => spacing};
  display: flex;
  align-items: center;
  font-size: 0.9rem;

  &:hover {
    ${(props) => props.theme.mixins.underlineHovered};
  }

  svg {
    width: 18px;
    margin-right: 5px;
    fill: ${(props) => props.colorName};
  }
`;

import React, { useEffect } from 'react';
import { StyledImagePropType, useCmsImage } from '@thisisdevelopment/akzonobel-core';
import { StyledImage } from './styled';

type PropTypes = {
  id: number;
};

function CmsImage({ id, ...rest }: PropTypes & StyledImagePropType) {
  const { image, fetchImage } = useCmsImage();

  useEffect(() => {
    fetchImage(id);
  }, [fetchImage, id]);

  return <StyledImage src={image?.src} {...rest} />;
}

export default CmsImage;

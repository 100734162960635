import styled from 'styled-components';
import { List, ListItem } from '@thisisdevelopment/akzonobel-core';
import { Link } from 'react-router-dom';

export const SearchBarStyled = styled.div`
  font-family: 'Source Sans Pro', sans-serif;

  @media (max-width: 480px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.white};
    z-index: 25;
  }
`;

export const SearchResults = styled.div`
  position: absolute;
  top: 100%;
  left: 39px;
  right: 20px;
  min-height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.2));
  border-radius: 0 0 5px 5px;
  z-index: 21;

  @media (max-width: 480px) {
    left: 0;
    right: 0;
    top: calc(100% - 1px);
  }
`;

export const SearchResultTitle = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.brandGold};
  color: ${({ theme }) => theme.colors.white};
  padding: 10px 20px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const SearchResultList = styled(List)``;

export const SearchResultListItem = styled(ListItem)`
  padding: 10px 20px;

  &:hover,
  &:focus-within {
    background-color: ${({ theme }) => theme.colors.brandGray100};
  }

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.gray4};
    font-size: 0.9rem;
    outline: none;
  }
`;

export const SearchField = styled.input`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.brandGold};
  color: ${({ theme }) => theme.colors.brandGold};
  padding: 10px;
  outline: none;
  font-size: 1.1rem;
  width: 400px;

  ::placeholder {
    color: ${({ theme }) => theme.colors.brandGold};
    opacity: 0.5;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const ShowAllResults = styled(Link)`
  display: block;
  background-color: ${({ theme }) => theme.colors.gray3};
  padding: 15px;
  margin-top: 10px;
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.gray5};
  font-weight: 600;
`;

export const Searching = styled.div`
  background-color: ${({ theme }) => theme.colors.brandGold};
  padding: 15px;
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  border-radius: 0 0 5px 5px;
`;

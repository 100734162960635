import React, { FC } from 'react';
import CmsImage from 'components/CmsImage';
import { H3 } from '@thisisdevelopment/akzonobel-core';
import { Link } from 'react-router-dom';
import { CmsCallToActionStyled } from './styled';

export type CmsCallToActionType = {
  title: string;
  button_label: string;
  button_link: string;
  file_id: number;
};

type PropType = {
  callToAction: CmsCallToActionType;
};

const CmsCallToAction: FC<PropType> = ({ callToAction }: PropType) => {
  return (
    <CmsCallToActionStyled>
      <CmsImage id={callToAction.file_id} />
      <H3>{callToAction.title}</H3>
      <Link to={callToAction.button_link}>{callToAction.button_label} &gt;</Link>
    </CmsCallToActionStyled>
  );
};

export default CmsCallToAction;

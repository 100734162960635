import { Product } from '@thisisdevelopment/akzonobel-core';

export const hasColors = (product: Product) => product.colorCollectionColorIds.length > 1;

export const isColorCard = (product: Product) => product.productType === 'Colorcard';

export const isAccessory = (product: Product) => product.productType === 'Accessory';

export const isColorCardByGlobalId = (product: Product) =>
  product.globalId === '032b6c6f-29bf-4fb5-b6e6-ad12010f42b0';

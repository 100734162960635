import { useState, useEffect, useRef, useMemo, useCallback } from 'react';

export default function useForm(initialValues: object = {}, validation: object = {}) {
  const [values, setValues]: any = useState(initialValues);
  const [errors, setErrors]: any = useState({});
  const [touches, setTouches]: any = useState({});
  const validationRef = useRef(validation);

  const invalid = useMemo(
    () => Object.keys(errors).some((error) => errors[error].length > 0),
    [errors]
  );

  useEffect(() => {
    if (validationRef.current) {
      const errors = Object.keys(validationRef.current).reduce(
        (res, field) => ({
          ...res,
          [field]: validationRef.current[field]
            .map((item) => item(values[field]))
            .filter((item) => item),
        }),
        {}
      );
      setErrors(errors);
    }
  }, [values, validationRef]);

  const bindInput = useCallback(
    (field: string) => {
      const value = values?.[field] ?? '';
      const touched = touches?.[field] ?? false;
      const error = errors?.[field]?.[0];

      return {
        value,
        touched,
        error,
        onChange: (newValue: string) => {
          setValues((values) => ({ ...values, [field]: newValue }));
          setTouches((touches) => ({ ...touches, [field]: true }));
        },
        onBlur: () => null,
      };
    },
    [values, touches, errors, setValues, setTouches]
  );

  const handleSubmit = useCallback(
    (callback: Function) => {
      Object.keys(values).forEach((field) =>
        setTouches((touches) => ({ ...touches, [field]: true }))
      );
      if (!invalid) {
        callback(values);
      }
    },
    [values, setTouches, invalid]
  );

  return {
    invalid,
    values,
    setValues,
    bindInput,
    handleSubmit,
  };
}

import React, { useState, useEffect, useCallback, FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { FlexRow, config } from '@thisisdevelopment/akzonobel-core';
import { TopbarFixed, Logo, TopbarStyled, Icons } from './styled';
import HeritageLogo from 'assets/images/logo';
import SearchBar from 'components/SearchBar';
import IconButton from 'components/IconButton';
import Menu from 'components/Menu';
import { ContainerFull, ContainerInner } from 'elements';
import MenuMobile from 'components/MenuMobile';
// import Modal from 'components/Modal';
// import SignInModal from 'components/SignInModal';
import Cart from 'components/Cart';
import { useMediaQuery } from 'hooks/useMediaQuery';

const Topbar: FC = () => {
  const theme = useTheme();
  const isHomePage = useRouteMatch('/en/')?.isExact;
  const shouldHaveBackground = useCallback(
    () => (isHomePage ? window.scrollY >= 150 : true),
    [isHomePage]
  );
  const shouldHaveBoxShadow = useCallback(
    () => (isHomePage ? window.scrollY >= 150 : window.scrollY > 0),
    [isHomePage]
  );
  const [hasBackground, setHasBackground] = useState<boolean>(false);
  const [hasBoxShadow, setHasBoxShadow] = useState<boolean>(false);
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const [isMenuMobileOpen, setIsMenuMobileOpen] = useState<boolean>(false);
  // const [isSignInModalOpen, setIsSignInModalOpen] = useState<boolean>(false);
  const isTablet = useMediaQuery(theme.mediaQuery.tablet);

  const onScroll = useCallback(() => {
    setHasBackground(shouldHaveBackground());
    setHasBoxShadow(shouldHaveBoxShadow());
  }, [setHasBackground, shouldHaveBackground, setHasBoxShadow, shouldHaveBoxShadow]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll, false);
    onScroll();
    return () => window.removeEventListener('scroll', onScroll, false);
  }, [onScroll]);

  return (
    <TopbarFixed>
      <ContainerFull height="100%">
        <TopbarStyled hasBackground={hasBackground} hasBoxShadow={hasBoxShadow}>
          <ContainerInner>
            <FlexRow justifyContent="space-between">
              <Logo to="/en/">
                <HeritageLogo />
              </Logo>
              {isSearchOpen ? (
                <SearchBar onClose={() => setIsSearchOpen(false)} />
              ) : (
                <>
                  <Menu hasBackground={hasBackground} />
                  <MenuMobile
                    isOpen={isMenuMobileOpen}
                    onClose={() => setIsMenuMobileOpen(false)}
                  />
                  <Icons>
                    <IconButton icon="Search" onClick={() => setIsSearchOpen(true)} />
                    {/* <IconButton icon="Heart" onClick={() => null} /> */}
                    {config.ecomEnabled && <Cart />}
                    {/* <Wrapper position="relative">
                      <IconButton icon="Person" onClick={() => setIsSignInModalOpen(true)} />
                      {isSignInModalOpen && (
                        <SignInModal onClose={() => setIsSignInModalOpen(false)} />
                      )}
                    </Wrapper> */}
                    {isTablet && (
                      <IconButton icon="Menu" onClick={() => setIsMenuMobileOpen(true)} />
                    )}
                  </Icons>
                </>
              )}
            </FlexRow>
          </ContainerInner>
        </TopbarStyled>
      </ContainerFull>
    </TopbarFixed>
  );
};

export default Topbar;

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import theme from 'theme';

type StyledPropType = {
  $highlight: boolean;
};

export const Image = styled.figure`
  position: relative;
  margin: 0;

  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
`;

export const SubCategory = styled.figcaption`
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px 30px;
  font-family: 'Source Sans Pro', sans-serif;
  color: ${({ theme }) => theme.colors.brandBlueDark};
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: ${({ theme }) => `${theme.colors.brandGray100}AA`};

  @media ${theme.mediaQuery.mobile} {
    display: none;
  }
`;

export const Content = styled.section`
  h2 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.brandGold};
    font-size: 1.6rem;
    font-weight: 600;
  }

  p {
    font-size: 1.2rem;
  }

  a {
    color: ${({ theme }) => theme.colors.brandGold};
    font-weight: 600;
    margin-left: 10px;

    &:after {
      content: '>';
    }
  }

  @media ${theme.mediaQuery.tablet} {
    margin: 0 15px;
  }

  @media ${theme.mediaQuery.mobile} {
    h2 {
      font-size: 1.2rem;
    }

    p {
      font-size: 1rem;
    }

    a {
      display: block;
      margin-left: unset;
      margin-top: 10px;
    }
  }
`;

export const InpirationCardStyled = styled(Link)<StyledPropType>`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${({ $highlight, theme }) =>
    $highlight &&
    css`
      @media ${theme.mediaQuery.desktop} {
        ${Content} {
          margin: 0 80px;
        }

        ${Image} {
          img {
            height: 600px;
          }
        }
      }
    `}
`;

import styled from 'styled-components';
import { Link } from 'react-router-dom';

type StyledPropType = {
  textColor?: string;
  bgColor?: string;
};

export const ColorCardStyled = styled(Link)<StyledPropType>`
  display: flex;
  flex-direction: column;
  transition: all ease 0.2s;
  ${(props) => props.theme?.animations?.slideUpFadeIn}
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  gap: 3px;

  &:hover {
    transform: scale(1.03);
    cursor: pointer;
  }
`;

export const ColorBlock = styled.div.attrs((props: StyledPropType) => ({
  style: {
    backgroundColor: props.bgColor,
  },
}))<StyledPropType>`
  position: relative;
  flex: 1;
`;

export const ColorName = styled.span`
  font-size: 0.7rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 2px;
  color: ${({ theme }) => theme.colors.black};
`;

export const ColorNameIn = styled(ColorName)<StyledPropType>`
  color: ${({ textColor }) => textColor};
  position: absolute;
  left: 4px;
  bottom: 4px;
  padding-left: unset;
  display: none;
`;

import React from 'react';
import { Link } from 'react-router-dom';
import { Paragraph, H3, H4 } from '@thisisdevelopment/akzonobel-core';
import { ImageAlign, SectionStyle, CmsSectionStyled, Content, Image } from './styled';
import { useMediaQuery } from 'hooks/useMediaQuery';
import theme from 'theme';

export type CmsSectionType = {
  title: string;
  text: string;
  button_link: string;
  button_label: string;
  file_id: number;
  image_align: ImageAlign;
  style: SectionStyle;
  spacing: boolean;
};

type PropType = {
  section: CmsSectionType;
  sectionKey: string;
};

function CmsSection({ section, sectionKey }: PropType) {
  const isDesktop = useMediaQuery(theme.mediaQuery.desktop);

  return (
    <CmsSectionStyled
      imageAlign={section.image_align}
      sectionStyle={section.style}
      hasSpacing={section.spacing}
    >
      <Content>
        {section.style === 'call2action' && isDesktop ? (
          <>
            <H3>{section.title.split('|')[0]}</H3>
            <H4>{section.title.split('|')[1]}</H4>
          </>
        ) : (
          <H3>{section.title.replace('|', ' ')}</H3>
        )}
        {section.text.split('\n').map((paragraph, index) => (
          <Paragraph key={`${sectionKey}_Paragraph_${index}`}>{paragraph}</Paragraph>
        ))}
        {section.button_link && (
          <Link to={section.button_link}>{section.button_label}&nbsp;&gt;</Link>
        )}
      </Content>
      {section.file_id && (
        <Link to={section.button_link}>
          <Image id={section.file_id} />
        </Link>
      )}
    </CmsSectionStyled>
  );
}

export default CmsSection;

import styled, { css } from 'styled-components';
import { FlexCol, List, ListItem } from 'elements';

export const ColorPhotosStyled = styled(FlexCol)`
  margin-bottom: 30px;
  width: 100%;
`;

export const ScrollLeft = styled.button`
  position: absolute;
  border: 0;
  outline: 0;
  background-color: ${(props) => props.theme.colors.gray2};
  left: 0;
  top: calc(50% - 30px);
  width: 60px;
  height: 60px;
  transition: all ease 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${(props) => props.theme.colorPhotos.scrollButtonColor};
    transform: rotate(90deg);
    width: 20px;
  }

  &:hover {
    background-color: ${(props) => props.theme.colorPhotos.scrollButtonColor};
    cursor: pointer;

    svg {
      fill: ${(props) => props.theme.colors.white};
    }
  }
`;

export const ScrollRight = styled(ScrollLeft)`
  left: unset;
  right: 0;

  svg {
    transform: rotate(-90deg);
  }
`;

type ColorPhotosPropType = {
  numberOfPhotos: number;
};

export const Photos = styled.div<ColorPhotosPropType>`
  display: flex;
  overflow: hidden;

  img {
    width: auto;
    height: 200px;
    margin-right: 20px;
  }

  @media (max-width: 480px) {
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      margin-right: 0;
      scroll-snap-align: start;
    }
    gap: 10px;
    display: grid;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    grid-template-columns: repeat(${({ numberOfPhotos }) => numberOfPhotos}, 100%);
  }
`;

export const Bullets = styled(List)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
`;

export const Bullet = styled(ListItem)<{ selected: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.gray4};
  margin-right: 10px;
  cursor: pointer;

  ${(props) =>
    props.selected &&
    css`
      background-color: ${(props) => props.theme.colors.gray2};
    `}
`;

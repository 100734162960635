import React, { FC, useEffect, useMemo, useState } from 'react';
import { H2, Color } from '@thisisdevelopment/akzonobel-core';
import groupBy from 'lodash/groupBy';
import ColorCard from 'components/ColorCard';
import {
  ColorPickerStyled,
  Groups,
  Group,
  GroupButton,
  SelectedGroup,
  ColorsContainer,
} from './styled';

type PropType = {
  colors: Color[];
  mode: 'Product' | 'Color';
};

const ColorPicker: FC<PropType> = ({ colors, mode }: PropType) => {
  const [selectedGroup, setSelectedGroup] = useState<string>();

  const colorsGrouped = useMemo(() => colors && groupBy(colors, 'sensation'), [colors]);

  useEffect(() => {
    colorsGrouped && setSelectedGroup(Object.keys(colorsGrouped)[0]);
  }, [colorsGrouped]);

  return (
    <ColorPickerStyled>
      <Groups>
        {Object.keys(colorsGrouped).map((group) => (
          <Group key={`ColorGroup_${group}`}>
            <GroupButton isActive={selectedGroup === group} onClick={() => setSelectedGroup(group)}>
              {group}
            </GroupButton>
          </Group>
        ))}
      </Groups>
      {}
      <SelectedGroup>
        <H2>{selectedGroup}</H2>
        <ColorsContainer>
          {selectedGroup &&
            colorsGrouped[selectedGroup].map((color) => (
              <ColorCard mode={mode} key={`Color_${color.colorCollectionColorId}`} color={color} />
            ))}
        </ColorsContainer>
      </SelectedGroup>
    </ColorPickerStyled>
  );
};

export default ColorPicker;

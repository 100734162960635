import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import theme from 'theme';

export const MenuStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
  height: 70px;

  a,
  span {
    font-family: 'Source Sans Pro', sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 600;
  }

  @media ${theme.mediaQuery.tablet} {
    display: none;
  }
`;

export const SubMenuBackground = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 44px;
  pointer-events: none;
  opacity: 0;
`;

export const SubMenuItems = styled.div`
  position: absolute;
  top: 70px;
  display: flex;
  align-items: center;
  gap: 30px;
  height: 44px;
  pointer-events: none;
  opacity: 0;
  margin-left: 22px;
`;

export const SubMenuItem = styled(NavLink)<{ hasBackground: boolean }>`
  ${({ hasBackground }) =>
    hasBackground
      ? css`
          color: ${({ theme }) => theme.colors.brandGray200};

          &:hover {
            color: ${({ theme }) => theme.colors.brandGold};
          }
        `
      : css`
          color: ${({ theme }) => theme.colors.white};

          &:hover {
            color: ${({ theme }) => theme.colors.brandGold};
          }
        `}
`;

export const MenuItemLink = styled(NavLink)`
  padding: 0 20px;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const MenuItem = styled.li<{ hasActiveChild: boolean; hasBackground: boolean }>`
  margin: 0;
  padding: 0;

  &:hover {
    cursor: pointer;
    ${({ hasBackground }) =>
      hasBackground
        ? css`
            background-color: ${({ theme }) => theme.colors.brandGray100};
            ${MenuItemLink} {
              color: ${({ theme }) => theme.colors.brandGray200};
            }
          `
        : css`
            background-color: ${({ theme }) => theme.colors.brandDarkGreen};
            ${MenuItemLink} {
              color: ${({ theme }) => theme.colors.white};
            }
          `}

    ${SubMenuBackground} {
      opacity: 1;
      pointer-events: unset;
      ${({ hasBackground }) =>
        hasBackground
          ? css`
              background-color: ${({ theme }) => theme.colors.brandGray100};
              z-index: 24;
            `
          : css`
              background-color: ${({ theme }) => theme.colors.brandDarkGreen};
              z-index: 24;
            `}
    }

    ${SubMenuItems} {
      opacity: 1;
      pointer-events: unset;
      z-index: 24;
    }
  }

  ${({ hasActiveChild }) =>
    hasActiveChild &&
    css`
      background-color: ${({ theme }) => theme.colors.brandGray050};

      ${MenuItemLink} {
        color: ${({ theme }) => theme.colors.brandGray200};
      }

      ${SubMenuBackground} {
        opacity: 1;
        pointer-events: unset;
        z-index: 21;
        background-color: ${({ theme }) => theme.colors.brandGray050};
      }

      ${SubMenuItems} {
        opacity: 1;
        pointer-events: unset;
        z-index: 22;
      }
    `}
`;

import { useState, useCallback } from 'react';
import { Product, PaginationInfo } from '../types';
import api from '../services/bff';

export default function useProducts(): {
  products: Product[];
  pagination: PaginationInfo | undefined;
  fetchProducts: (query?: string) => void;
  fetchAllProducts: (page?: number, previousResponse?: Product[]) => void;
  isLoading: boolean;
} {
  const [products, setProducts] = useState<Product[]>([]);
  const [pagination, setPagination] = useState<PaginationInfo | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const fetchAllProducts = useCallback(async (page = 1, previousResponse = []) => {
    const fetch = async () => {
      setIsLoading(true);
      const query = new URLSearchParams();
      query.set('page', `${page}`);
      const resp = await api.getProducts(query.toString());
      return resp;
    };

    await fetch().then((newResponse) => {
      const response = [...previousResponse, ...newResponse.data.products];
      const paginationInfo = newResponse.data.pagination;

      if (paginationInfo.currentPage < paginationInfo.totalPages) {
        page++;

        return fetchAllProducts(page, response);
      }

      setProducts(response);
      setIsLoading(false);
    });
  }, []);

  const fetchProducts = useCallback(async (query?: string) => {
    const fetch = async () => {
      try {
        setIsLoading(true);
        const resp = await api.getProducts(query);
        setProducts(resp.data.products);
        setPagination(resp.data.pagination);
      } finally {
        setIsLoading(false);
      }
    };
    await fetch();
  }, []);

  return {
    products,
    pagination,
    fetchProducts,
    fetchAllProducts,
    isLoading,
  };
}

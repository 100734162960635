import React, { useEffect, useState, useContext, useCallback } from 'react';
import useStores from '../../hooks/useStores';
import loadGoogleApi from '../../util/googleApiLoader';
import { DistanceRadius, Store } from '../../types';
import Svg from '../../components/Svg';
import Button from '../../components/Button';
import GoogleMap from '../../components/GoogleMap';
import GooglePlaceSearch from '../../components/GooglePlaceSearch';
import StoreCard from '../../components/StoreCard';
import { FlexCol, Paragraph, Label, Text, List } from '../../elements';
import config from '../../config';
import { ThemeContext } from 'styled-components';
import { FindStoresStyled, Headline, Title } from './styled';

type PropType = {
  showFilters?: boolean;
  title: string;
  headline?: string;
  initialCenter: { lat: number; lng: number };
  initialZoom: number;
  distanceRadius?: DistanceRadius;
};

function FindStores({
  showFilters = false,
  title,
  headline,
  initialCenter,
  initialZoom,
  distanceRadius = '30',
}: PropType) {
  const theme = useContext(ThemeContext);
  const [googleApiLoaded, setGoogleApiLoaded] = useState(false);
  const { stores, fetchStores } = useStores(distanceRadius);
  const [store, setStore]: [Store | undefined, (store: Store) => void] = useState();
  const [zoom, setZoom] = useState<number>(initialZoom);
  const [center, setCenter] = useState<{ lat: number; lng: number }>(initialCenter);
  const [hasSearched, setHasSearched] = useState<boolean>(false);

  useEffect(() => {
    const load = async () => {
      await loadGoogleApi(config.googleApiKey);
      setGoogleApiLoaded(true);
    };
    load();
  }, [setGoogleApiLoaded]);

  const onPlacesChanged = useCallback(
    (places: google.maps.places.PlaceResult[]) => {
      const place: google.maps.places.PlaceResult = places[0];
      if (place.geometry?.location) {
        setCenter({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
        setZoom(10);
        setHasSearched(true);
        fetchStores(place.geometry.location.lat(), place.geometry.location.lng());
      }
    },
    [setCenter, setZoom, setHasSearched, fetchStores]
  );

  const handleSearchThisArea = ({ center }: { center: google.maps.LatLng; zoom: number }) => {
    fetchStores(center.lat(), center.lng());
  };

  return (
    <FindStoresStyled>
      <FlexCol>
        <Title>{title}</Title>
        {headline && <Headline>{headline}</Headline>}
        <Label fontWeight="bold" mt="20px" mb="5px" htmlFor="searchInput">
          Search in your area
        </Label>
        {googleApiLoaded && (
          <GooglePlaceSearch onPlacesChanged={onPlacesChanged} onGetLocation={fetchStores} />
        )}

        {showFilters && (
          <Button size="large">
            <Svg.FiltersIcon fill={theme.findStore.iconsColor} width="20px" />
            <Text mr="10px">Filters</Text>
          </Button>
        )}

        {stores.length > 0 ? (
          <>
            <Paragraph margin="20px 0 0 0" fontWeight="bold">
              Nearest stores
            </Paragraph>
            <List
              flex="1"
              overflowY="scroll"
              border="1px 0 0 0"
              borderColor={theme.findStore.borderColor}
            >
              {stores.map((item, index) => (
                <StoreCard
                  key={`Store_${index}`}
                  store={item}
                  onClick={setStore}
                  isExpanded={store === item}
                />
              ))}
            </List>
          </>
        ) : (
          <FlexCol margin="50px 0 0 0" alignItems="center">
            <Svg.MarkerEmpty fill={theme.findStore.iconsColor} width="80px" />
            {hasSearched ? (
              <Paragraph fontSize="xl" margin="10px" fontWeight="semibold" textAlign="center">
                There are no stores in this area
              </Paragraph>
            ) : (
              <>
                <Paragraph fontSize="xl" margin="10px" fontWeight="semibold" textAlign="center">
                  Please enter a location
                </Paragraph>
                <Paragraph margin="10px" fontSize="lg" textAlign="center">
                  Enter your city or address or click on the map to find a store near you.
                </Paragraph>
              </>
            )}
          </FlexCol>
        )}
      </FlexCol>
      {googleApiLoaded && (
        <GoogleMap
          center={center}
          zoom={zoom}
          stores={stores}
          store={store}
          onClickMarker={setStore}
          searchThisAreaMinZoom={10}
          onSearchThisArea={handleSearchThisArea}
        />
      )}
    </FindStoresStyled>
  );
}

export default FindStores;

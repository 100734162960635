import { useState, useCallback } from 'react';
import { Store, DistanceUnit, DistanceRadius } from '../types';
import api from '../services/bff';

export default function useStores(distanceRadius: DistanceRadius) {
  const [stores, setStores]: [Store[], Function] = useState([]);

  const fetchStores = useCallback(
    async (latitude: number, longitude: number, uom = DistanceUnit.Metric) => {
      const fetch = async () => {
        const resp = await api.getStores(latitude, longitude, uom, distanceRadius);
        setStores(resp.data);
      };
      await fetch();
    },
    []
  );

  return {
    stores,
    fetchStores,
  };
}

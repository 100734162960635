import React, { useRef, useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Store } from '../../types';
import Svg from '../Svg';
import { formatDistance } from '../../util/number';
import config from '../../config';
import { StoreCardStyled, Carret, PhoneLink } from './styled';
import { FlexRow, Text, Paragraph, Wrapper } from '../../elements';

type PropTypes = {
  store: Store;
  onClick: (store: Store) => void;
  isExpanded?: boolean;
};

function StoreCard({ store, onClick, isExpanded = false }: PropTypes) {
  const theme = useContext(ThemeContext);
  const ref = useRef<HTMLLIElement>(null);
  const formatAddresslineCityFirst = ['UK', 'GB'].includes(store.address.countryCode);

  useEffect(() => {
    if (isExpanded && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  }, [isExpanded]);

  return (
    <StoreCardStyled onClick={() => onClick(store)} ref={ref} isExpanded={isExpanded}>
      <FlexRow justifyContent="space-between" alignItems="center" padding="10px">
        <Wrapper flex="1">
          <Text fontWeight="extrabold" fontSize="sm">
            {store.companyName}
          </Text>
        </Wrapper>
        {isExpanded ? (
          <Svg.MarkerFilled fill={theme.findStore.iconsColor} width="18px" />
        ) : (
          <Svg.MarkerEmpty fill={theme.findStore.iconsColor} width="18px" />
        )}
        <Text margin="0 15px 0 10px" fontSize="sm">
          {formatDistance(store.distanceValue, config.distanceUnit)}
        </Text>
        <Carret isExpanded={isExpanded} fill={theme.findStore.iconsColor} />
      </FlexRow>
      <Paragraph padding="0 0 0 10px" margin="0 0 5px 0" fontSize="sm">
        {store.address.address1}
      </Paragraph>
      <Paragraph padding="0 0 0 10px" margin="0 0 10px 0" fontSize="sm">
        {formatAddresslineCityFirst
          ? `${store.address.city} ${store.address.zip}`
          : `${store.address.zip} ${store.address.city}`}
      </Paragraph>
      {isExpanded && (
        <Wrapper padding="0 0 0 10px">
          <PhoneLink href={`tel:${store.address.primaryPhone}`}>
            {store.address.primaryPhone}
          </PhoneLink>
          <Paragraph padding="0" margin="0 0 5px 0" fontSize="sm">
            Brands
          </Paragraph>
          <Paragraph padding="0" margin="0 0 10px 0" fontSize="sm">
            Dulux
          </Paragraph>
        </Wrapper>
      )}
    </StoreCardStyled>
  );
}

export default StoreCard;

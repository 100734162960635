import React, { FC, ReactNode } from 'react';
import { ContainerInner } from 'elements';
import { Paragraph, H1, H2 } from '@thisisdevelopment/akzonobel-core';
import { PageStyled, Header } from './styled';
import theme from 'theme';

type PropType = {
  title: string;
  headline?: string;
  children?: ReactNode;
  bgColor?: string;
  color?: string;
  showHeader?: boolean;
  prefix?: string;
  prefixColor?: string;
};

const Page: FC<PropType> = ({
  title,
  headline,
  children,
  bgColor = theme.colors.white,
  color = theme.colors.brandGold,
  showHeader = true,
  prefix,
  prefixColor = theme.colors.brandGold,
}: PropType) => {
  return (
    <PageStyled bgColor={bgColor}>
      {showHeader && (
        <Header $color={color} $prefixColor={prefixColor}>
          <ContainerInner>
            {prefix && <H2>{prefix}</H2>}
            <H1>{title}</H1>
            {headline && <Paragraph>{headline}</Paragraph>}
          </ContainerInner>
        </Header>
      )}
      {children}
    </PageStyled>
  );
};

export default Page;

import React from 'react';
import { Colors, ColorBlockStyled, ColorCombinationsStyled, Blocks } from './styled';
import { Color, Text, Wrapper, H2 } from '@thisisdevelopment/akzonobel-core';
import { ContainerInner } from 'elements';
import { useTheme } from 'styled-components';

type PropTypes = {
  color: Color;
};

type ColorCombinationPropTypes = {
  color: Color;
  scheme: Color[];
  title: string;
};

function ColorBlock({ color }: { color: Color }) {
  return (
    <ColorBlockStyled
      to={`/en/colours/${color.colorCollectionColorId}/${color.colorCollectionColorId}`}
      bgColor={`#${color.rgb}`}
    >
      <Text>{color.defaultColorName}</Text>
    </ColorBlockStyled>
  );
}

function ColorCombination({ color, scheme, title }: ColorCombinationPropTypes) {
  return (
    <Colors>
      <div>{title}</div>
      <ColorBlock color={color} />
      <ColorBlock color={scheme[0]} />
      <ColorBlock color={scheme[1]} />
    </Colors>
  );
}

function ColorCombinations({ color }: PropTypes) {
  const theme = useTheme();

  return (
    <Wrapper bgColor={theme.colors.white}>
      <ContainerInner>
        <ColorCombinationsStyled>
          <H2>{color.defaultColorName} works well with:</H2>
          <Blocks>
            {color.schemesEnriched?.tonalCombinations && (
              <ColorCombination
                color={color}
                scheme={color.schemesEnriched.tonalCombinations}
                title={'Tonal'}
              />
            )}
            {color.schemesEnriched?.neutralCombinations && (
              <ColorCombination
                color={color}
                scheme={color.schemesEnriched.neutralCombinations}
                title={'Co-ordinating'}
              />
            )}
            {color.schemesEnriched?.designerCombinations && (
              <ColorCombination
                color={color}
                scheme={color.schemesEnriched.designerCombinations}
                title={'Designers Choice'}
              />
            )}
          </Blocks>
        </ColorCombinationsStyled>
      </ContainerInner>
    </Wrapper>
  );
}

export default ColorCombinations;

import { AxiosResponse } from 'axios';
import axios, { initAxiosConfig, deleteTokenLocalStore } from './axiosConfig';
import {
  ProductFilters,
  DistanceUnit,
  Store,
  Color,
  Article,
  Product,
  HueColor,
  PaginationInfo,
  SearchParams,
  SearchResult,
  DistanceRadius,
} from '../../types';

export default {
  axios,

  initAxiosConfig,

  deleteTokenLocalStore,

  getFilters(query: string): Promise<AxiosResponse<ProductFilters[]>> {
    return axios.get(`/productFilters?${query}`);
  },

  getColorsHueList(query: string): Promise<AxiosResponse<{ colorFamily: HueColor[] }>> {
    return axios.get(`/colors/huelist?${query}`);
  },

  getColors(query: string): Promise<AxiosResponse<{ colors: { color: Color }[] }>> {
    return axios.get(`/colors?${query}`);
  },

  getColor(cccid: string): Promise<AxiosResponse<Color>> {
    return axios.get(`/colors/${cccid}`);
  },

  getProducts(
    query?: string
  ): Promise<AxiosResponse<{ products: Product[]; pagination: PaginationInfo }>> {
    return axios.get(`/products${query ? `?${query}` : ''}`);
  },

  getProductByFriendlyName(friendlyName: string): Promise<AxiosResponse<Product>> {
    return axios.get(`/products/${friendlyName}`);
  },

  getProductByGlobalId(globalId: string): Promise<AxiosResponse<Product>> {
    return axios.get(`/products/${globalId}`);
  },

  getArticle(
    globalId: string,
    cccid: number | null,
    packSize: string
  ): Promise<AxiosResponse<Article>> {
    return axios.get(
      `/products/${globalId}/articleSelect?packSize=${packSize}${cccid ? `&cccid=${cccid}` : ''}`
    );
  },

  getStores(
    latitude: number,
    longitude: number,
    uom: DistanceUnit,
    distanceRadius: DistanceRadius
  ): Promise<AxiosResponse<{ stores: Store[] }>> {
    return axios.get(
      `/stores?lat=${latitude}&lon=${longitude}&uom=${uom}&viewport=${distanceRadius}`
    );
  },

  // Todo: don't know if it is going to be possible to type the response
  getCmsItem(collection: string): Promise<any> {
    return axios.get(`/cms/items/${collection}`);
  },

  getCmsItemById(collection: string, id: number): Promise<any> {
    return axios.get(`/cms/items/${collection}/${id}`);
  },

  // Todo: this one may be possible to type
  getCmsFile(id: number): Promise<any> {
    return axios.get(`/cms/files/${id}`);
  },

  getSearch({ query, type, perPage = 3, page = 1 }: SearchParams): Promise<
    AxiosResponse<{ results: SearchResult[]; pagination: PaginationInfo }>
    // any
  > {
    return axios.get(`/search?query=${query}&type=${type}&perPage=${perPage}&page=${page}`);
    // return Promise.resolve(type === 'colors' ? searchResultsColors : searchResultsProducts);
  },

  sendContact(payload: any): Promise<AxiosResponse<any>> {
    const headers = {
      Accept: 'application/json',
      'accept-language': 'en',
    };
    return axios.post('/lgy/surveys', payload, { headers });
  },
};

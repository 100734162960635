import React, { ReactNode } from 'react';
import { Product, Paragraph, Image, LinkButton, H4 } from '@thisisdevelopment/akzonobel-core';
import { Content, ProductOptions, ProductCardStyled, ImageWrapper, InfoWrapper } from './styled';
import ProductKeySellingPoints from 'components/ProductKeySellingPoints';
import { hasColors } from 'util/product';
import TagManager from 'react-gtm-module';
import { getArgsProductClick } from 'util/datalayer';

type PropTypes = {
  product: Product;
  children: ReactNode;
};

const onProductCardClick = (product: Product, withColorPicker: boolean) => {
  TagManager.dataLayer(getArgsProductClick(product, withColorPicker));
};

function ProductCard({ product, children }: PropTypes) {
  return (
    <ProductCardStyled>
      <Content>
        <ImageWrapper>
          <Image src={product.packshots?.[0].s} alt={product.name} />
        </ImageWrapper>
        <InfoWrapper>
          <H4>{product.name}</H4>
          <Paragraph textAlign="justify" fontSize="xl">
            {product.shortDescription}
          </Paragraph>
          <ProductKeySellingPoints product={product} />
          <ProductOptions>
            <LinkButton
              color="secondary"
              size="small"
              to={`/en/products/details/${product.uriFriendlyName}`}
              onClick={() => onProductCardClick(product, false)}
            >
              Product Details
            </LinkButton>
            {hasColors(product) && (
              <LinkButton
                color="secondary"
                size="small"
                to={`/en/products/details/${product.uriFriendlyName}?pickAColour=true`}
                onClick={() => onProductCardClick(product, true)}
              >
                Pick a colour
              </LinkButton>
            )}
          </ProductOptions>
        </InfoWrapper>
      </Content>
      {children}
    </ProductCardStyled>
  );
}

export default ProductCard;

import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { MenuItemLink } from 'components/Menu/styled';
import { ContainerInner } from 'elements';
import theme from 'theme';

export const TopbarFixed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 114px;
  z-index: 20;

  @media ${theme.mediaQuery.tablet} {
    position: sticky;
    height: 70px;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

type TopbarStyledPropType = {
  hasBackground: boolean;
  hasBoxShadow: boolean;
};

export const TopbarStyled = styled.div<TopbarStyledPropType>`
  position: relative;
  height: 100%;
  transition: all ease 0.5s;

  svg:hover {
    fill: ${({ theme }) => theme.colors.brandGray200};
  }

  ${({ hasBackground, theme }) =>
    hasBackground
      ? css`
          background-color: rgba(255, 255, 255, 0.95);

          ${MenuItemLink} {
            color: ${theme.colors.brandGold};
          }

          svg {
            fill: ${theme.colors.brandGold};
          }
        `
      : css`
          background-color: 'transparent';

          ${MenuItemLink} {
            color: ${theme.colors.white};
          }

          svg {
            fill: ${theme.colors.white};
          }
        `};

  ${({ hasBoxShadow }) =>
    hasBoxShadow &&
    css`
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    `};

  @media ${theme.mediaQuery.tablet} {
    svg {
      fill: ${({ theme }) => theme.colors.brandGold};
    }

    ${ContainerInner} {
      margin-left: unset;
      margin-right: unset;
    }
  }
`;

export const Logo = styled(NavLink)`
  background-color: ${({ theme }) => theme.colors.brandBlue};
  height: 101px;
  width: 176px;
  padding: 10px;
  position: relative;
  z-index: 25;

  svg {
    width: 100%;
    height: 100%;
  }

  @media ${theme.mediaQuery.tablet} {
    height: 70px;
    width: 120px;
  }
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  gap: 10px;
  position: relative;

  svg {
    width: 28px;
    height: 28px;
  }

  @media ${theme.mediaQuery.tablet} {
    margin-right: 15px;
    gap: 15px;

    svg {
      width: 36px;
      height: 36px;
    }
  }
`;

import React from 'react';
import { Color, getColorNameByBgColorHex } from '@thisisdevelopment/akzonobel-core';
import { useParams } from 'react-router-dom';
import { ColorCardStyled, ColorBlock, ColorName, ColorNameIn } from './styled';

type PropTypes = {
  color: Color;
  mode: 'Product' | 'Color';
};

function ColorCard({ color, mode }: PropTypes) {
  const textColor = getColorNameByBgColorHex(color.rgb);
  const { friendlyName } = useParams<{ friendlyName: string }>();

  const linkTo =
    mode === 'Product'
      ? `/en/products/details/${friendlyName}?cccid=${color.colorCollectionColorId}`
      : `/en/colours/${color.colorCollectionColorId}/${color.uriFriendlyName}`;

  return (
    <ColorCardStyled key={`ColorCard_${color.colorCollectionColorId}`} to={linkTo}>
      <ColorBlock bgColor={`#${color.rgb}`}>
        <ColorNameIn textColor={textColor}>{color.defaultColorName}</ColorNameIn>
      </ColorBlock>
      <ColorName>{color.defaultColorName}</ColorName>
    </ColorCardStyled>
  );
}

export default ColorCard;

import Cart from '../../assets/images/cart.svg';
import Bucket from '../../assets/images/bucket.svg';
import Minus from '../../assets/images/minus.svg';
import Heart from '../../assets/images/heart.svg';
import Download from '../../assets/images/download.svg';
import Pdf from '../../assets/images/pdf.svg';
import KeyInfo from '../../assets/images/key-info.svg';
import KeyInfoFinish from '../../assets/images/key-info-finish.svg';
import KeyInfoCoverage from '../../assets/images/key-info-coverage.svg';
import KeyInfoDrying from '../../assets/images/key-info-drying.svg';
import KeyInfoCoats from '../../assets/images/key-info-coats.svg';
import Check from '../../assets/images/check.svg';
import Plus from '../../assets/images/plus.svg';
import SelectYourColor from '../../assets/images/select-your-color.svg';
import Close from '../../assets/images/close.svg';
import AddCircle from '../../assets/images/add-circle.svg';
import Carret from '../../assets/images/carret.svg';
import ArrowRight from '../../assets/images/arrow-right.svg';
import FiltersIcon from '../../assets/images/filters.svg';
import MarkerEmpty from '../../assets/images/marker-empty.svg';
import MarkerFilled from '../../assets/images/marker-filled.svg';
import LocationPicker from '../../assets/images/location-picker.svg';
import Search from '../../assets/images/search.svg';
import Menu from '../../assets/images/menu.svg';
import ShoppingBag from '../../assets/images/shopping-bag.svg';
import Person from '../../assets/images/person.svg';
import ExpandLess from '../../assets/images/expand-less.svg';
import ExpandMore from '../../assets/images/expand-more.svg';

export default {
  Cart,
  Bucket,
  Minus,
  Heart,
  Download,
  Pdf,
  KeyInfo,
  KeyInfoFinish,
  KeyInfoCoverage,
  KeyInfoDrying,
  KeyInfoCoats,
  Check,
  Plus,
  SelectYourColor,
  Close,
  AddCircle,
  Carret,
  ArrowRight,
  FiltersIcon,
  MarkerEmpty,
  MarkerFilled,
  LocationPicker,
  Search,
  Menu,
  ShoppingBag,
  Person,
  ExpandLess,
  ExpandMore,
};

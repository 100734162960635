import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { H2, Paragraph } from '@thisisdevelopment/akzonobel-core';
import theme from 'theme';
import CmsImage from 'components/CmsImage';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { InpirationCardStyled, Image, SubCategory, Content } from './styled';

//TODO Pulled from pages/Inspiration, check if this is ok, now we have model duplication and issues when we change the model, is there a better location for (shared) models?
export type InspirationType = {
  id: number;
  title: string;
  headline: string;
  summary: string;
  body: string;
  image: number;
  thumbnail: number;
  category: string[];
  sub_category: string;
  highlight: boolean;
  friendly_url_title: string;
};

type PropType = {
  article: InspirationType;
  highlight?: boolean;
};

const InspirationCard: FC<PropType> = ({ article, highlight = false }: PropType) => {
  const isMobile = useMediaQuery(theme.mediaQuery.mobile);
  const linkTo = `/en/inspiration/article/${article.id}/${article.friendly_url_title}`;
  const body = (
    <>
      <Image>
        <Link to={linkTo}>
          <CmsImage id={article.image} />
        </Link>
        <SubCategory>{article.sub_category}</SubCategory>
      </Image>
      <Content>
        <H2>{article.title}</H2>
        <Paragraph>
          {article.headline}
          {(isMobile || highlight) && <Link to={linkTo}>Read more</Link>}
        </Paragraph>
      </Content>
    </>
  );

  if (isMobile || highlight) {
    return (
      <InpirationCardStyled $highlight={highlight} as="div">
        {body}
      </InpirationCardStyled>
    );
  }

  return (
    <InpirationCardStyled $highlight={highlight} to={linkTo}>
      {body}
    </InpirationCardStyled>
  );
};

export default InspirationCard;

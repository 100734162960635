import React from 'react';
import { StyledPropType, ButtonStyled, LinkButtonStyled, ExternalLinkButtonStyled } from './styled';

type ButtonPropType = {
  children: JSX.Element | JSX.Element[] | string;
  onClick?: Function;
  disabled?: boolean;
};

type LinkButtonPropType = {
  to?: string;
};

type ExternalLinkButtonPropType = {
  href?: string;
  target?: string;
};

function Button({
  children,
  onClick,
  disabled,
  variant = 'button',
  size = 'medium',
  color = 'primary',
  ...rest
}: ButtonPropType & StyledPropType) {
  return (
    <ButtonStyled
      onClick={() => onClick?.()}
      disabled={disabled}
      variant={variant}
      size={size}
      color={color}
      {...rest}
    >
      {children}
    </ButtonStyled>
  );
}

export const LinkButton = ({
  to,
  children,
  onClick,
  disabled,
  variant,
  size,
  color,
  ...rest
}: ButtonPropType & LinkButtonPropType & StyledPropType) => (
  <LinkButtonStyled to={to || '#'} {...rest}>
    <Button
      onClick={() => onClick?.()}
      disabled={disabled}
      variant={variant}
      size={size}
      color={color}
    >
      {children}
    </Button>
  </LinkButtonStyled>
);

export const ExternalLinkButton = ({
  children,
  disabled,
  variant,
  size,
  color,
  href,
  target,
  onClick,
  ...rest
}: ButtonPropType & ExternalLinkButtonPropType & StyledPropType) => (
  <ExternalLinkButtonStyled href={href} target={target} rel="noreferrer" {...rest}>
    <Button
      onClick={() => onClick?.()}
      disabled={disabled}
      variant={variant}
      size={size}
      color={color}
    >
      {children}
    </Button>
  </ExternalLinkButtonStyled>
);

export default Button;

import React from 'react';
import { Link } from 'react-router-dom';
import { ContainerInner } from 'elements';
import { Anchor, Wrapper, H3, List, ListItem, config } from '@thisisdevelopment/akzonobel-core';
import { FooterStyled, Links, Bottom, LinksContainer, Social } from './styled';
import PinterestLogo from 'assets/images/pinterest';
import FacebookLogo from 'assets/images/facebook';
import InstagramLogo from 'assets/images/instagram';

function Footer() {
  const year = new Date().getFullYear();

  return (
    <FooterStyled>
      <Links>
        <ContainerInner>
          <LinksContainer>
            <Wrapper>
              <H3>Dulux Heritage</H3>
              <List>
                <ListItem>
                  <Link to="/en/contact-us">Contact us</Link>
                </ListItem>
                <ListItem>
                  <Link to="/en/stores">Find a Stockist</Link>
                </ListItem>
                {config.ecomEnabled && (
                  <ListItem>
                    <Link to="/en/delivery-and-returns-policy">Refunds and Cancellations</Link>
                  </ListItem>
                )}
                <ListItem>
                  <Link to="/en/terms-and-conditions">Terms and Conditions</Link>
                </ListItem>
              </List>
            </Wrapper>
            <Wrapper>
              <H3>Access</H3>
              <List>
                <ListItem>
                  <Link to="/en/cookie-policy">Cookie Policy</Link>
                </ListItem>
                {config.showCookieSettings && (
                  <a
                    className="a2-text-link ot-sdk-show-settings is-light"
                    data-component="a2-text-link"
                    href="javascript:void(0);"
                    target="_self"
                  >
                    Cookie Settings
                  </a>
                )}
                <ListItem>
                  <Link to="/en/privacy-policy">Privacy Policy</Link>
                </ListItem>
              </List>
            </Wrapper>
            <Wrapper>
              <H3>Related sites</H3>
              <List>
                {config.relatedSites?.length > 0 &&
                  config.relatedSites.map((relatedSite, idx) => {
                    return (
                      <ListItem key={idx}>
                        <Anchor href={relatedSite.url} target="_blank" rel="noopener noreferrer">
                          {relatedSite.title}
                        </Anchor>
                      </ListItem>
                    );
                  })}
              </List>
            </Wrapper>
            <Wrapper>
              <H3>Follow us on</H3>
              <Social>
                {config.socials?.facebook && (
                  <Anchor href={config.socials?.facebook} target="_blank" rel="noopener noreferrer">
                    <FacebookLogo />
                  </Anchor>
                )}
                {config.socials?.instagram && (
                  <Anchor
                    href={config.socials?.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramLogo />
                  </Anchor>
                )}
                {config.socials?.pinterest && (
                  <Anchor
                    href={config.socials?.pinterest}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <PinterestLogo />
                  </Anchor>
                )}
              </Social>
            </Wrapper>
          </LinksContainer>
        </ContainerInner>
      </Links>
      <Bottom>
        <ContainerInner>Copyright © {year} - AkzoNobel</ContainerInner>
      </Bottom>
    </FooterStyled>
  );
}

export default Footer;

import styled, { css } from 'styled-components';
import { ListItem } from '../../elements';
import Svg from '../Svg';

type StyledPropTypes = {
  isExpanded: boolean;
};

export const shouldForwardProp = (prop: string, defaultValidatorFn: Function) =>
  !['isExpanded'].includes(prop) && defaultValidatorFn(prop);

export const StoreCardStyled = styled(ListItem).withConfig({ shouldForwardProp })<StyledPropTypes>`
  border-bottom: 1px solid ${(props) => props.theme.findStore.borderColor};
  cursor: pointer;

  ${(props) =>
    props.isExpanded &&
    css`
      background-color: ${props.theme.findStore.selectedStoreBgColor};
    `}
`;

export const Carret = styled(Svg.Carret).withConfig({ shouldForwardProp })<StyledPropTypes>`
  width: 15px;
  transition: all ease 0.5s;

  ${(props) =>
    props.isExpanded &&
    css`
      transform: rotate(-180deg);
    `}
`;

export const PhoneLink = styled.a`
  ${({ theme }) => theme.mixins.underlineHover(theme.findStore.iconsColor)}

  &:hover {
    ${({ theme }) => theme.mixins.underlineHovered}
  }
`;
